/*
 * Copyright 2021 Orchestral Developments Limited
 */
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Nodes, Node } from './Interfaces'
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { useHistory, useParams } from "react-router-dom";
import FindRole from './FindRole'

type SubTreeProps = {
    value: Node
    nodePrefix: String
}

/** Recursive function to display Roles of a Node */
function SubTree({ value, nodePrefix }: SubTreeProps) {
    return (
        <>
            <TreeItem nodeId={`${nodePrefix}${value.Name}`} label={value.Name} key={value.Name} >
                {
                    value.Children.map(child => <SubTree value={child} key={child.Name} nodePrefix={`${nodePrefix}${value.Name}/`} />)
                }
                {
                    value.Roles.map((role) =>
                        <TreeItem nodeId={role.Name} label={role.Name} key={role.Name} />
                    )
                }
            </TreeItem>
        </>
    )
}

type RoleTreeProps = {
    rolesData: Nodes
}

/** Iterate over JSON data to display Roles information
 *  Allow a Search facility
*/
export default function RoleTree({ rolesData }: RoleTreeProps) {
    // Build an array of top level Node Names to expand initially
    let topLevelNodeIds: Array<string> = [];
    rolesData.Node.forEach(node => {
        topLevelNodeIds.push(`node:${node.Name}`)
    })
    const history = useHistory();
    const { roleName } = useParams<{ roleName: string | undefined }>();
    const { env } = useParams<{ env: string }>();
    const [expanded, setExpanded] = React.useState<string[]>(topLevelNodeIds);
    // When a particular Node is selected, make it expandable
    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const displayRoleDetails = (event: React.ChangeEvent<{}>, selectedNode: string = "") => {
        const matchedRole = FindRole(rolesData, selectedNode)
        if (matchedRole) {
            history.push("/roles/"+ env + "/" + matchedRole.Name)
        }
        else {
            history.push("/roles/"+ env)
        }
    }
    return (
        <>
            <Divider></Divider>
            <TreeView
                expanded={expanded}
                selected={roleName || ""}
                onNodeToggle={handleToggle}
                onNodeSelect={displayRoleDetails}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}>
                {
                    rolesData.Node.map((treeItemData) => (
                        // Start with `node:` prefix to help provide unique and deterministic nodeId for TreeItem
                        <SubTree value={treeItemData} key={treeItemData.Name} nodePrefix="node:" />
                    ))
                }
            </TreeView>
        </>
    )
}