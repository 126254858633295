/*
 * Copyright 2021 Orchestral Developments Limited
 */
import { Nodes, Children } from './Interfaces'

function searchAndFilter(nodes: Children, searchString: String): Children {
    let matchedNodes: Children = [];

    nodes.forEach(node => {
        // if node.Name matches, save this node in the array and return
        if (node.Name.toLowerCase().includes(searchString.toLowerCase())) {
            matchedNodes.push(node)
            return
        }

        // if any node.Roles matches, save the Roles in the array
        const matchedRoles = node.Roles.filter(role => role.Name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))

        // if any Children Name or Role matches, save the Children in the array
        const matchedChildren = searchAndFilter(node.Children, searchString)
        if (matchedRoles.length > 0 || matchedChildren.length > 0) {
            matchedNodes.push({ ...node, Roles: matchedRoles, Children: matchedChildren })
        }
    })
    return matchedNodes
}

export default function SearchMatchingNodes(searchString: string, rolesData: Nodes) {
    const result: Nodes = {
        Node: searchAndFilter(rolesData.Node, searchString)
    }
    return result
}