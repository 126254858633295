/*
 * Copyright 2021 Orchestral Developments Limited
 */
import './App.css';
import { NodesData } from './Interfaces'
import { useState, useEffect } from 'react';
import DisplayRoles from './DisplayRolesDetails'
import Alert from '@material-ui/lab/Alert';
import DisplayLoading from './DisplayLoading'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import RequestAccess from './RequestAccess';
import { NodesGlobalContext } from './NodesGlobalContext';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RequestAccessStatus from './RequestAccessStatus';
import { AppBar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { generatePath } from "react-router";
const environmentRouteOptions = process.env.REACT_APP_ENVIRONMENTS?.split(",").join("|")
const rolesPath = `/roles/:env(${environmentRouteOptions})/:roleName?`
const requestRolePath = `/request/:env(${environmentRouteOptions})/:roleName`
const statusPath = '/user-status'
const environments = process.env.REACT_APP_ENVIRONMENTS?.split(",", 5) || []

function RolesBrowser(): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState("")
  const { oidcUser } = useReactOidc();
  const [nodes, setNodes] = useState<NodesData>({})
  const environmentRoutes = environments.map(name => generatePath(rolesPath, { env: name }))
  const routeMatch = useRouteMatch([...environmentRoutes, statusPath]);
  const currentTab = routeMatch?.path || false;
  const defaultPath = "/roles/".concat(environments[0])
  useEffect(() => {
    var result: NodesData = {}
    const fetchData = async () => {
      try {
        for (let environment of environments) {
          const url = `${process.env.REACT_APP_URL}/roles/${environment}`
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${oidcUser.access_token}`,
            },
          })
          if (response.status !== 200) {
            throw new Error(`Got response ${response.status} from API`)
          }
          result[environment] = await response.json()
        }
        setNodes(result)
      } catch (e) {
        setErr(e.toString())
      }

      setLoading(false)
    }
    // Only fetch data if it hasn't already been loaded
    if (loading) {
      fetchData()
    }
  }, [oidcUser.access_token, loading]);

  if (err !== "") {
    return (
      <div>
        <Alert severity="error"> Error : {err.toString()} </Alert>
      </div>
    )
  }
  if (loading) {
    return <DisplayLoading />
  }

  return <NodesGlobalContext.Provider value={nodes}>
    <CssBaseline />
    <Container >
      <AppBar position="fixed">
        <Tabs value={currentTab}>
          {environments.map(name =>
            <Tab
              label={name}
              component={Link}
              to={generatePath(rolesPath, { env: name })}
              value={generatePath(rolesPath, { env: name })}
              key={name}
            />
          )}
          <Tab
            label='Access Request Status'
            component={Link}
            to={statusPath}
            value={statusPath}
          />
        </Tabs>
      </AppBar>
    </Container>
    <Switch>
      <Route path={statusPath}>
        <RequestAccessStatus />
      </Route>
      <Route path={requestRolePath}>
        <RequestAccess />
      </Route>
      <Route path={rolesPath}>
        <DisplayRoles />
      </Route>
      <Route path="/">
        <Redirect to={defaultPath} />
      </Route>
    </Switch>
  </NodesGlobalContext.Provider>

}
export default RolesBrowser
