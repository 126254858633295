/*
 * Copyright 2021 Orchestral Developments Limited
 */
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { Role } from './Interfaces'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link, useParams } from 'react-router-dom';
import FindRole from './FindRole'
import { Alert } from '@material-ui/lab';
import { useCurrentEnvironmentNodes } from './NodesGlobalContext';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sensitiveData: {
            marginLeft: theme.spacing(2),
        },
    }),
);

/** Displays information about a selected role, to be displayed in the right-hand side of the role browser */
export default function RoleDetails() {
    const { roleName } = useParams<{ roleName: string | undefined }>();
    const { env } = useParams<{ env: string }>();
    const nodes = useCurrentEnvironmentNodes()
    if (roleName === undefined) {
        return null;
    }
    const matchedRole = FindRole(nodes, roleName)
    if (matchedRole === undefined) {
        return (
            <div data-testid="undefined-role">
                <Alert severity="error"> Error : {roleName} does not exist </Alert>
                <p>Go back: <a href="/">Home</a></p>
            </div>)
    }

    return (
        <>
            <Typography variant="h6" gutterBottom data-testid="role-name">
                {matchedRole.Name}
                <SensitiveDataChip role={matchedRole} />
            </Typography>
            <Typography variant="subtitle1" gutterBottom data-testid="role-description">
                {matchedRole.Description}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Permissions:
            </Typography>
            <ul data-testid="role-permissions">
                {
                    matchedRole.Permissions.map((perm: string) => (
                        <li key={perm}>{perm}</li>
                    ))
                }
            </ul>
            <div>
                <Button component={Link} to={"/request/" + env + "/" + matchedRole.Name} variant="contained" color="primary">
                    Request Access
                </Button>
            </div>
        </>
    )
}

type SensitiveDataChipProps = {
    role: Role
}

/** If a selected role grants access to sensitive data, this function displays that additional information */
function SensitiveDataChip({ role }: SensitiveDataChipProps) {
    const classes = useStyles();
    if (!role.SensitiveData) {
        return null;
    }
    return <Tooltip title={`${role.Name} grants permission to sensitive data`} arrow>
        <Chip label="Sensitive Data" color="secondary" size="small" className={classes.sensitiveData} data-testid="role-sensitive" />
    </Tooltip>
}