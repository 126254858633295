/*
 * Copyright 2021 Orchestral Developments Limited
 */
import Alert from '@material-ui/lab/Alert';

export default function DisplayError() {
    const message = new URLSearchParams(document.location.search).get('message') || "There was an error"
    return (
        <div>
            <Alert severity="error"> Error : {message} </Alert>
            <p>Please try again: <a href="/">Retry</a></p>
        </div>
    )
}