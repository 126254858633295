/*
 * Copyright 2021 Orchestral Developments Limited
 */
import { createContext, useContext } from 'react';
import { NodesData } from './Interfaces'
import { useParams } from 'react-router-dom';
export const NodesGlobalContext = createContext<NodesData>({})
export const useNodes = () => useContext(NodesGlobalContext)
export const useCurrentEnvironmentNodes = () => {
    const { env } = useParams<{ env: string }>();
    const nodes = useNodes();
    return nodes[env];
}