/*
 * Copyright 2021 Orchestral Developments Limited
 */
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link, makeStyles, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { DateTime } from "luxon";
import React, { useState } from 'react';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import FindRole from './FindRole'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DisplayLoading from './DisplayLoading';
import { useCurrentEnvironmentNodes } from './NodesGlobalContext';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: '5px',
            padding: 5
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(10),
    }
}));

export default function RequestAccess() {
    const classes = useStyles();
    const oneYearFromToday = DateTime.now().plus({ years: 1 }).toISODate()
    const tomorrow = DateTime.now().plus({ days: 1 }).toISODate()
    const { oidcUser } = useReactOidc();
    const { roleName } = useParams<{ roleName: string }>();
    const nodes = useCurrentEnvironmentNodes();
    const { env } = useParams<{ env: string }>();
    const matchedRole = FindRole(nodes, roleName)
    const [selectedDate, setSelectedDate] = React.useState(oneYearFromToday);
    const [reasonForAccess, setReasonForAccess] = useState("");
    const [acknowledgedSecurityPolicies, setAcknowledgedSecurityPolicies] = useState(false);
    const [dateError, setDateError] = useState<React.ReactNode>();

    const [submitError, setSubmitError] = useState("")
    const [success, setSuccess] = useState(false)

    const handleDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        setSelectedDate(value || "");
    };
    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcknowledgedSecurityPolicies(event.target.checked);
    };
    const onErrorDate = (error: React.ReactNode, value: string | number | object | Date | null | undefined) => {
        error ? setDateError(error.toString()) : setDateError(undefined)
    };
    const [loading, setLoading] = useState(false)
    const postData = async () => {
        try {
            const body = {
                reason: reasonForAccess,
                accessRequiredUntil: selectedDate,
                role: roleName,
                acknowledgedSecurityPolicies: acknowledgedSecurityPolicies
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${oidcUser.access_token}`
                },
                body: JSON.stringify(body)
            };
            const url = `${process.env.REACT_APP_URL}/roles/${env}/request-access`
            setLoading(true)
            const response = await fetch(url, requestOptions)
            if (response.status !== 200) {
                throw new Error(`Got response ${response.status} from API: ${await response.text()}`)
            }
            setSuccess(true)
        } catch (e) {
            setSubmitError(e.toString())
        }
        setLoading(false)
    }
    const clickRequestAccess = () => {
        postData()
    }
    const formValid = (reasonForAccess !== "") && (!dateError) && (acknowledgedSecurityPolicies)

    if (matchedRole === undefined) {
        return (
            <main className={classes.content}>
                <div>
                    <Alert severity="error"> Error : {roleName} does not exist </Alert>
                    <p>Go back: <a href="/">Home</a></p>
                </div>
            </main>
        )
    }
    if (submitError !== "") {
        return (
            <main className={classes.content}>
                <div data-testid="failure">
                    <Alert severity="error"> Something went wrong while requesting the access: {submitError.toString()} </Alert>
                    <p>Please try again: <a href="/">Retry</a></p>
                </div>
            </main>
        )
    }
    if (loading) {
        return <DisplayLoading />
    }
    if (success) {
        return (
            <main className={classes.content}>
                <div data-testid="success">
                    <Alert severity="success">Access Request has been submitted!</Alert>
                    <p>Go back: <a href="/">Home</a></p>
                </div>
            </main>
        );
    }
    return (
        <>
            <CssBaseline />
            <Container maxWidth="sm">
                <main className={classes.content}>
                    <Typography variant="h5" gutterBottom color="primary">
                        You are requesting access to: {roleName}
                    </Typography>
                    <TextField
                        margin="dense"
                        multiline
                        size="medium"
                        rows={4}
                        required
                        fullWidth
                        id="reason-for-access"
                        label="Reason for the access"
                        value={reasonForAccess}
                        helperText="Please enter the reason you are requesting access to this role"
                        onChange={e => setReasonForAccess(e.target.value)}
                    />
                    <p></p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                            <KeyboardDatePicker
                                required
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="access-required-until"
                                label="Access Required Until"
                                value={selectedDate}
                                disablePast
                                error={dateError !== undefined}
                                onError={onErrorDate}
                                maxDate={oneYearFromToday}
                                minDate={tomorrow}
                                onChange={handleDateChange}
                                helperText={dateError ? dateError : "Please enter a date until which this access is required"}
                                maxDateMessage="Date cannot be more than a year from today"
                                minDateMessage="Date must be in the future"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <p></p>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox required name="TermsAndConditions" checked={acknowledgedSecurityPolicies} onChange={handleCheckBoxChange} />}
                            label={<Typography style={{ wordWrap: "break-word" }} variant="body2" color="textSecondary">By being granted access to the customer SaaS environments, you understand the rights and responsibilities associated with it and acknowledge that you will adhere to current Orion Health Information Security Policies listed in the
                                <Link href="https://msp.orionhealth.global/pages/viewpage.action?spaceKey=MSPPortal&title=Information+Security+Portal+Home"> Information Security Portal </Link></Typography>}
                        />
                    </FormGroup>
                    <p></p>
                    <div className={classes.root}>
                        <Button onClick={clickRequestAccess} variant="contained" color="primary" disabled={!formValid}>
                            Request Access
                        </Button>
                        {/* TODO Navigate to the list of pending access requests once JML-734 is done */}
                        <Button component={RouterLink} to={"/"} variant="contained" color="secondary">
                            Cancel
                        </Button>
                    </div>
                </main>
            </Container>
        </>
    );
}