/*
 * Copyright 2021 Orchestral Developments Limited
 */
import './App.css';
import RolesBrowser from './RolesBrowser'
import { AuthenticationProvider, oidcLog, OidcSecure } from '@axa-fr/react-oidc-context';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserManagerSettings } from 'oidc-client';
import DisplayError from './DisplayError'
import DisplayLoading from './DisplayLoading'

function App(): JSX.Element {

  const config: UserManagerSettings = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_MAIN_URL + '/authentication/callback',
    response_type: 'code',
    post_logout_redirect_uri: process.env.REACT_APP_MAIN_URL,
    scope: 'openid profile',
    authority: 'https://orionhealth.onelogin.com/oidc/2',
    // silent_redirect_uri seems unused since OneLogin has a refresh token
    // and this field is marked as optional, but not providing it breaks the @axa-fr/react-oidc-context library
    silent_redirect_uri: process.env.REACT_APP_MAIN_URL + '/authentication/silent_callback',
    automaticSilentRenew: true,
    loadUserInfo: true,
  };

  return (
    <div>
      <Router>
        <AuthenticationProvider
          configuration={config}
          loggerLevel={process.env.NODE_ENV === 'development' ? oidcLog.DEBUG : oidcLog.INFO}
          isEnabled={true}
          authenticating={() => <div>Authenticating...</div>}
          notAuthenticated={DisplayError}
          notAuthorized={DisplayError}
          callbackComponentOverride={DisplayLoading}
          sessionLostComponent={() => <div>Session Lost...</div>}
        >
          <OidcSecure>
            <RolesBrowser />
          </OidcSecure>
        </AuthenticationProvider>
      </Router>
    </div>

  )
}

export default App;
