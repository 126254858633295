/*
 * Copyright 2021 Orchestral Developments Limited
 */
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Button } from "@material-ui/core";
import { GridRenderCellParams } from '@mui/x-data-grid';
import { AccessRequest } from "./Interfaces";
import { useAccessRequestCallbacks } from "./RequestAccessStatusContext";

export function CancelPendingAction(params: GridRenderCellParams) {
  const { oidcUser } = useReactOidc();
  const accessRequest = params.row as AccessRequest
  const reloadCallback = useAccessRequestCallbacks()
  const postData = async () => {
    try {
      const body = {
        requestedDate: accessRequest.RequestedDate,
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${oidcUser.access_token}`
        },
        body: JSON.stringify(body)
      };
      const url = `${process.env.REACT_APP_URL}/user/cancel-pending-action`
      const response = await fetch(url, requestOptions)
      if (response.status !== 200) {
        reloadCallback(`Something went wrong while cancelling your request: ${await response.text()}`, false)
      } else {
        reloadCallback("Success! Your access request has been cancelled", true)
      }
    } catch (e) {
        reloadCallback(`Something went wrong while cancelling your request`, false)
    }
  }
  return (
    <div>
      <Button variant="contained" onClick={postData} color="primary">Cancel</Button>
    </div>
  );
}