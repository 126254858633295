/*
 * Copyright 2021 Orchestral Developments Limited
 */
import { Button, Tooltip } from "@material-ui/core";
import { GridCellParams } from '@mui/x-data-grid';

export function renderCellExpand(params: GridCellParams) {
    const value = params.value?.toString() || "";
    if (value.length > 20)
        return <>
            {value.substring(0, 30)}...<Tooltip interactive arrow title={value}>
                <Button color="primary">Show All</Button>
            </Tooltip>
        </>
    else
        return <>{value}</>;
}