/*
 * Copyright 2021 Orchestral Developments Limited
 */
import { Nodes, Role, Node } from './Interfaces'

/** Recursive function to find a role from nested structure */
function getChildrenMatchingRole(node: Node, selectedNode: string): Role | undefined {
    for (let child of node.Children) {
        const result = getChildrenMatchingRole(child, selectedNode)
        if (result !== undefined) {
            return result
        }
    }
    return node.Roles.find(role => role.Name === selectedNode)
}

export default function findRole(rolesData: Nodes, role: string): Role | undefined {
    for (let eachNode of rolesData.Node) {
        const matchingNodeRole = getChildrenMatchingRole(eachNode, role)
        if (matchingNodeRole !== undefined) {
            return matchingNodeRole;
        }
    }
    return undefined
}