/*
 * Copyright 2021 Orchestral Developments Limited
 */
import { Container, Snackbar, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useState, useEffect, useCallback } from 'react';
import Alert from '@material-ui/lab/Alert';
import { AccessRequest, AccessRequestArray, Children, NodesData, UserStatusResult } from './Interfaces'
import { DataGrid, GridColDef, GridColumns, GridSortModel } from '@mui/x-data-grid';
import { renderCellExpand } from './RenderCellExpand';
import { useNodes } from './NodesGlobalContext';
import * as React from 'react';
import { Button } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import { ApproveRejectAction } from './ApproveRejectAction';
import { RequestAccessStatusContext } from './RequestAccessStatusContext';
import { CancelPendingAction } from './CancelPendingAction';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(10),
    }
  }),
);
const tableColumns: GridColDef[] = [
  {
    field: 'Role',
    headerName: 'Role',
    width: 200,
  },
  {
    field: 'Environment',
    headerName: 'Environment',
    width: 200,
  },
  {
    field: 'AccessRequiredUntil',
    headerName: 'Access Required Until',
    width: 300,
  },
  {
    field: 'Reason',
    headerName: 'Reason',
    width: 300,
    renderCell: renderCellExpand,
  },
];
const tableColumnsCurrentRequests: GridColDef[] = [
  {
    field: 'Role',
    headerName: 'Role',
    width: 200,
  },
  {
    field: 'Environment',
    headerName: 'Environment',
    width: 200,
  },
  {
    field: 'AccessRequiredUntil',
    headerName: 'Access Required Until',
    width: 300,
  },
  {
    field: 'Reason',
    headerName: 'Reason',
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: 'Renew',
    headerName: 'Renew',
    sortable: false,
    width: 200,
    renderCell: (params) => {
      const accessRequest = params.row as AccessRequest
      const newLink = `/request/${accessRequest.Environment}/${accessRequest.Role}`
      return <Button component={RouterLink} to={newLink} variant="contained" color="primary">
        Renew Access
      </Button>
    },
  },
];
const tableColumnsPendingRequests: GridColDef[] = [
  {
    field: 'Role',
    headerName: 'Role',
    width: 200,
  },
  {
    field: 'Environment',
    headerName: 'Environment',
    width: 200,
  },
  {
    field: 'AccessRequiredUntil',
    headerName: 'Access Required Until',
    width: 300,
  },
  {
    field: 'Reason',
    headerName: 'Reason',
    width: 400,
    renderCell: renderCellExpand,
  },
  {
    field: 'Cancel',
    headerName: 'Cancel',
    sortable: false,
    width: 200,
    renderCell: CancelPendingAction,
  },
];
const waitingForApprovalColumns: GridColDef[] = [
  {
    field: 'Role',
    headerName: 'Role',
    width: 150,
  },
  {
    field: 'User',
    headerName: 'User',
    width: 200,
  },
  {
    field: 'RequestedDate',
    headerName: 'RequestedDate',
    width: 200,
  },
  {
    field: 'Environment',
    headerName: 'Environment',
    width: 150,
  },
  {
    field: 'AccessRequiredUntil',
    headerName: 'Access Required Until',
    width: 200,
  },
  {
    field: 'Reason',
    headerName: 'Reason',
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: 'Approve',
    headerName: 'Approve',
    sortable: false,
    width: 200,
    renderCell: ApproveRejectAction,
  },
  {
    field: 'Reject',
    headerName: 'Reject',
    sortable: false,
    width: 200,
    renderCell: ApproveRejectAction,
  },
];

export default function RequestAccessStatus(): JSX.Element {
  const nodes = useNodes()
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState("")
  const [snackbarAlertSuccess, setSnackbarAlertSuccess] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  //Reload the data when Approve/Reject/Cancel button is clicked
  const reloadData = useCallback(
    (resultMessage: string, success: boolean) => {
      setSnackbarOpen(true)
      setSnackbarMessage(resultMessage)
      setSnackbarAlertSuccess(success)
      setLoading(true)
    },
    [],
  );
  const { oidcUser } = useReactOidc();
  let name: string = oidcUser.profile.preferred_username!;
  const environments = process.env.REACT_APP_ENVIRONMENTS?.split(",", 5) || []
  const userIsApprover = checkIfUserIsApproverInAnyEnv(environments, nodes, name)
  const [pendingApprovals, setPendingApprovals] = useState<AccessRequestArray>([])
  const [userStatus, setUserStatus] = useState<UserStatusResult>({
    Pending: [],
    Rejected: [],
    Expired: [],
    Current: []
  })
  const classes = useStyles();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlRequests = `${process.env.REACT_APP_URL}/user/access-requests`
        const response = await fetch(urlRequests, {
          headers: {
            Authorization: `Bearer ${oidcUser.access_token}`,
          },
        })
        if (response.status !== 200) {
          throw new Error(`Got response ${response.status} from API`)
        }
        setUserStatus(await response.json())
        if (userIsApprover) {
          const urlApprovals = `${process.env.REACT_APP_URL}/user/approvals`
          const responseApprovals = await fetch(urlApprovals, {
            headers: {
              Authorization: `Bearer ${oidcUser.access_token}`,
            },
          })
          if (responseApprovals.status !== 200) {
            throw new Error(`Got response ${responseApprovals.status} from API`)
          }
          setPendingApprovals(await responseApprovals.json())
        }
      } catch (e) {
        setErr(e.toString())
      }
      setLoading(false)
    }
    // Only fetch data if it hasn't already been loaded
    if (loading) {
      fetchData()
    }
  }, [oidcUser.access_token, loading, userIsApprover]);
  if (err !== "") {
    return (
      <div> <br /> <br /> <br />
        <Alert severity="error"> Error : {err.toString()} </Alert>
      </div>
    )
  }
  const handleClose = () => {
    setSnackbarOpen(false)
  };
  return (
    <RequestAccessStatusContext.Provider value={reloadData}>
      <Container maxWidth="xl">
        <main className={classes.content}>
          <div data-testid="user-status" className={classes.toolbar} style={{ height: 200, width: '100%' }}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Alert onClose={handleClose} severity={snackbarAlertSuccess ? "success" : "error"}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
            {userIsApprover &&
              <><Typography variant="h5" gutterBottom>
                Requests waiting for my approval
              </Typography>
                <PrintTable
                  defaultSortColumn="Role"
                  accessRequests={pendingApprovals}
                  loading={loading}
                  tableColumns={waitingForApprovalColumns}
                  noAccessRequestsText="You have no access requests to approve" />
                <br /></>
            }
            <Typography variant="h5" gutterBottom>
              Current Roles
            </Typography>
            <PrintTable
              defaultSortColumn="Role"
              accessRequests={userStatus.Current}
              loading={loading}
              tableColumns={tableColumnsCurrentRequests}
              noAccessRequestsText="You do not have access to any roles" /> <br />
            <Typography variant="h5" gutterBottom>
              Pending Requests
            </Typography>
            <PrintTable
              defaultSortColumn="Role"
              accessRequests={userStatus.Pending}
              loading={loading}
              tableColumns={tableColumnsPendingRequests}
              noAccessRequestsText="You do not have any pending roles" />
            <br />
            <Typography variant="h5" gutterBottom>
              Rejected Requests
            </Typography>
            <PrintTable
              defaultSortColumn="AccessRequiredUntil"
              accessRequests={userStatus.Rejected}
              loading={loading}
              tableColumns={tableColumns}
              noAccessRequestsText="You do not have any rejected roles" /> <br />
            <Typography variant="h5" gutterBottom>
              Expired Requests
            </Typography>
            <PrintTable
              defaultSortColumn="AccessRequiredUntil"
              accessRequests={userStatus.Expired}
              loading={loading}
              tableColumns={tableColumns}
              noAccessRequestsText="You do not have any expired roles" />
          </div>
        </main>
      </Container>
    </RequestAccessStatusContext.Provider>
  );
}

function checkIfUserIsApproverInAnyEnv(environments: string[], nodes: NodesData, email: string): boolean {
  return environments.some(env => checkIfUserIsApprover(nodes[env].Node, email))
}

function checkIfUserIsApprover(nodes: Children, email: string): boolean {
  return nodes.some(node => node.Roles.some(role => role.Approvers.includes(email)) || checkIfUserIsApprover(node.Children, email))
}

function PrintTable(props: { defaultSortColumn: string, accessRequests: AccessRequestArray, loading: boolean, noAccessRequestsText: string, tableColumns: GridColumns }): JSX.Element {
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: props.defaultSortColumn,
      sort: 'asc',
    },
  ]);
  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      getRowId={(r) => r.User + r.RequestedDate}
      rows={props.accessRequests}
      columns={props.tableColumns}
      loading={props.loading}
      localeText={{
        noRowsLabel: props.noAccessRequestsText,
      }}
      autoHeight
      disableVirtualization
    />)
}
