/*
 * Copyright 2021 Orchestral Developments Limited
 */
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useMemo, useState } from 'react';
import RoleTree from './RoleTree'
import RoleDetails from './RoleDetails'
import { TextField } from '@material-ui/core'
import SearchMatchingNodes from './Search'
import { useCurrentEnvironmentNodes } from './NodesGlobalContext';
const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            top: 48,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            top: 48,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(10),
        }
    }),
);

/** Displays existing Roles on the left hand side and related information about a selected role on the right hand side */
export default function DisplayRoles() {
    const classes = useStyles();
    const [query, setQuery] = useState("")
    const nodes = useCurrentEnvironmentNodes()
    const filteredRoles = useMemo(() => SearchMatchingNodes(query, nodes), [query, nodes])
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" noWrap>
                        Role Information
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <TextField type="search" placeholder="Search" variant="outlined" value={query} onChange={e => setQuery(e.target.value)} />
                <RoleTree rolesData={filteredRoles} />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <RoleDetails />
            </main>
        </div>
    );
}